<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-card
    :loading="loading"
    outlined
    class="vqb-group card"
    :class="'depth-' + depth.toString()"
  >
    <v-card-text class="vqb-group-heading card-header">
      <v-row
        class="match-type-container form-inline"
        align="center"
        justify="space-between"
      >
        <!-- <v-col cols="auto">
          <label
            class="mr-2"
            for="vqb-match-type"
          >
            {{ labels.matchType }}
          </label>
        </v-col> -->
        <v-col cols="auto">
          <v-btn-toggle
            id="vqb-match-type"
            v-model="query.logicalOperator"
          >
            <v-btn
              v-for="label in labels.matchTypes"
              :key="label.id"
              :value="label.id"
              outlined
              :disabled="!hasMultipleRule"
              color="primary"
              small
            >
              <span style="font-weight: 600;">{{ label.label }}</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-spacer />

        <v-col
          v-if="existsPropAndFalse('hideDefaultBtnClear')"
          cols="auto"
        >
          <v-row>
            <v-col v-if="depth > 1" cols="auto">
              <v-btn
                  color="error"
                  @click="remove"
                  small
              >
                <v-icon left>
                  $delete
                </v-icon>
                {{ labels.removeGroup }}
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="query.children.length>0">
              <v-btn
                  color="error"
                  @click="clearGroup"
                  small
              >
                <v-icon left>
                  $iconify_feather-trash
                </v-icon>
                Очистить
              </v-btn>
            </v-col>
            <v-col v-if="exportAndImportEnabled" cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab color="primary" elevation="2" v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="$refs.importFile.click()">
                    <input v-show="false" ref="importFile" type="file" accept="application/json" @change="uploadFile">
                    <v-list-item-title>Загрузить</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="query.children.length > 0" @click="exportRules">
                    <v-list-item-title>Сохранить</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />

    <v-card-text class="vqb-group-body card-body">
      <v-row
        class="rule-actions form-inline"
        align="center"
      >
        <v-col cols="auto">
          <v-autocomplete
              v-model="selectedRule"
              :loading="loading"
              outlined
              hide-details
              :items="rules"
              item-value="id"
              item-text="label"
              style="width: 300px"
              placeholder="Выберите условие"
              return-object>
          <template v-slot:item="{ props, item }">
            <v-icon v-if="item.is_main">
              mdi-star
            </v-icon>
            {{ item.label }}
          </template>
            <template v-slot:selection="{ props, item }">
              <v-icon v-if="item.is_main">
                mdi-star
              </v-icon>
              {{ item.label }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="secondary"
            :disabled="!selectedRule"
            @click="addRule"
            small
          >
            <v-icon left>
              $iconify_plus-outlined
            </v-icon>
            {{ labels.addRule }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="depth < maxDepth"
            color="secondary"
            @click="addGroup"
            small
          >
            <v-icon left>
              $iconify_plus-circle-outlined
            </v-icon>
            {{ labels.addGroup }}
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="!!query">
        <v-row
          dense
          v-for="(child, indexChild) in query.children"
          :key="indexChild"
        >
          <v-col>
            <component
              :is="child.type"
              :type="child.type"
              :query.sync="child.query"
              :rule-types="ruleTypes"
              :rules="rules"
              :rule="ruleById(child.query.rule)"
              :index="indexChild"
              :max-depth="maxDepth"
              :depth="depth + 1"
              :labels="labels"
              @child-deletion-requested="removeChild"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import moment from "moment";

const cloneDeep  = require('clone-deep');
  import QueryBuilderRule from './QueryBuilderRule.vue'

  export default {
    name: 'QueryBuilderGroup',
    components: {
      QueryBuilderRule,
    },
    props: {
      ruleTypes: Object,
      type: {
        type: String,
        default: 'query-builder-group',
      },
      exportAndImportEnabled: false,
      modelType: null,
      query: Object,
      rules: Array,
      index: Number,
      maxDepth: Number,
      depth: Number,
      labels: Object,
      hideDefaultBtnClear: Boolean,
      loading: Boolean,
    },
    data () {
      return {
        dialog: false,
        fileFilter: null,
        selectedRule: this.rules[0],
      }
    },
    computed: {
      hasMultipleRule () {
        return this.query.children.length > 1
      },
      program () {
        return this.$store.getters['company/program/program']
      },
    },
    watch: {
      rules (v) {
        this.selectedRule = v && v.length > 0 ? v[0] : null
      },
    },
    methods: {
      getComponent (type) {
        return type === 'query-builder-group'
          ? this.groupComponent
          : this.ruleComponent
      },
      existsPropAndFalse (propName) {
        return !Object.keys(this.$options.propsData).includes(propName) ||
          (
            Object.keys(this.$options.propsData).includes(propName) &&
            this.$options.propsData[propName] === false
          )
      },
      ruleById (ruleId) {
        var rule = {title: 'Условие больше не поддерживается' }

        this.rules.forEach(function (value) {
          if (value.id === ruleId) {
            rule = value
            return false
          }
        })

        return rule
      },

      addRule () {
        if (!this.selectedRule || !this.ruleById(this.selectedRule.id)) return
        console.log('addRule', this.selectedRule)
        const updatedQuery = cloneDeep (this.query)
        const child = {
          type: 'query-builder-rule',
          query: {
            rule: this.selectedRule.id,
            operator: this.selectedRule.operators && this.selectedRule.operators.length > 0 ? this.selectedRule.operators[0] : undefined,
            operand: typeof this.selectedRule.operands === 'undefined' ? this.selectedRule.id : this.selectedRule.operands[0],
            value: null,
          },
        }

        console.log('addRule.child', this.selectedRule)
        // A bit hacky, but `v-model` on `select` requires an array.
        if (this.ruleById(child.query.rule).type === 'multi-select') {
          child.query.value = []
        }
        console.log('children.push(child)', child)
        updatedQuery.children.push(child)
        this.$emit('update:query', updatedQuery)
      },

      addGroup () {
        const updatedQuery = cloneDeep (this.query)
        if (this.depth < this.maxDepth) {
          updatedQuery.children.push({
            type: 'query-builder-group',
            query: {
              logicalOperator: this.labels.matchTypes[0].id,
              children: [],
            },
          })
          this.$emit('update:query', updatedQuery)
        }
      },

      remove () {
        this.$emit('child-deletion-requested', this.index)
      },
      clearGroup () {
        const updatedQuery = cloneDeep (this.query)
        updatedQuery.children = []
        this.$emit('update:query', updatedQuery)
      },
      removeChild (index) {
        const updatedQuery = cloneDeep (this.query)
        updatedQuery.children.splice(index, 1)
        this.$emit('update:query', updatedQuery)
      },
      exportRules() {
        const data = {
          type: this.modelType,
          version: 1,
          date: moment().format('YYYY-MM-DDTHH:mm:ss'),
          programId: this.program.id,
          data: this.query 
        }
        const outputFile = JSON.stringify(data)
        const blob = new Blob([outputFile], {type: 'text/plain'})
        const e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
        let fileName = "_filter_" + this.program.id + "_" + moment().format('YYYY-MM-DDTHHmmss') + ".json"
        if (this.modelType) fileName = this.modelType + fileName
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
      },
      uploadFile(e) {
        const file = e.target.files[0]
        if (file) {
          if (!file.name.match(".json$", "i")) {
            this.$notify({
              title: 'Загрузка',
              text: 'Тип файла должен быть .json',
              type: 'error',
            })
            return
          }
          let promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            const vm = this;
            reader.onload = e => {
              resolve((vm.fileinput = reader.result));
            };
            reader.readAsText(file);
          });

          promise.then(
              result => {
                this.$emit('update:query', JSON.parse(this.fileinput).data)
              },
              error => {
                console.log(error);
              }
          );
        }
      },
    },
  }
</script>
