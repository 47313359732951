<template>
  <div class="vue-query-builder">
    <v-row v-if="$IsDebugMode()" no-gutters>
      {{ query }}
    </v-row>
    <slot v-bind="vqbProps">
      <query-builder-group
          :exportAndImportEnabled="exportAndImportEnabled"
          :modelType="modelType" v-bind="vqbProps" :query.sync="query":loading="loading" />
    </slot>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import QueryBuilderGroup from "./components/QueryBuilderGroup.vue";
const cloneDeep = require("clone-deep");

var defaultLabels = {
  matchType: "Match Type",
  matchTypes: [
    { id: "AND", label: "И" },
    { id: "OR", label: "ИЛИ" },
  ],
  addRule: "Правило",
  removeRule: "Удалить",
  addGroup: "Группа",
  removeGroup: "Удалить",
  textInputPlaceholder: "Значение",
};

export default {
  name: "VueQueryBuilder",
  components: {
    QueryBuilderGroup,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hideDefaultBtnClear: {
      type: Boolean,
      default: false,
    },
    exportAndImportEnabled: {type: Boolean, default: false},
    modelType: null,
    rules: Array,
    labels: {
      type: Object,
      default() {
        return defaultLabels;
      },
    },
    maxDepth: {
      type: Number,
      default: 3,
      validator: function (value) {
        return value >= 1;
      },
    },
    value: Object,
  },

  data() {
    return {
      query: {
        logicalOperator: this.labels.matchTypes[0].id,
        children: [],
      },
      ruleTypes: {
        text: {
          // operators: ['equals', 'does not equal', 'contains', 'does not contain', 'is empty', 'is not empty', 'begins with', 'ends with'],
          operators: [
            { id: "equals", label: "равно" },
            { id: "not_equals", label: "не равно" },
            { id: "contains", label: "содержит" },
            { id: "not_contains", label: "не содержит" },
            { id: "empty", label: "пусто" },
            { id: "not_empty", label: "не пусто" },
            { id: "begins_with", label: "начинаеться с" },
            { id: "ends_with", label: "заканчивается" },
          ],
          inputType: "text",
          id: "text-field",
        },
        date: {
          operators: [
            { id: "=", label: "=" },
            { id: "!=", label: "!=" },
            { id: "<", label: "<" },
            { id: "<=", label: "<=" },
            { id: ">", label: ">" },
            { id: ">=", label: ">=" },
            { id: "date_today", label: "Сегодня" },
            { id: "date_tomorrow", label: "Завтра" },
            { id: "date_yesterday", label: "Вчера" },
            { id: "date_next_7days", label: "Следующие 7 дней" },
            { id: "date_next_30days", label: "Следующие 30 дней" },
            { id: "date_next_60days", label: "Следующие 60 дней" },
            { id: "date_next_90days", label: "Следующие 90 дней" },
            { id: "date_last_7days", label: "Прошедшие 7 дней" },          
            { id: "date_last_30days", label: "Прошедшие 30 дней" },          
            { id: "date_last_60days", label: "Прошедшие 60 дней" },          
            { id: "date_last_90days", label: "Прошедшие 90 дней" },          
            { id: "diff_days_current_date", label: "наступит ровно через" },
            { id: "days_gt_current_date", label: "наступит больше через" },
            { id: "days_before_current_date", label: "ровно N дней назад" },
            { id: "days_lt_current_date", label: "более N дней назад" },
            { id: "date_next_days", label: "наступит в следующие" }, // >= now            
            { id: "date_last_days", label: "наступила в прошедшие" }, // <= now
            { id: "is_null", label: "Не указана" },
            { id: "is_not_null", label: "Любая дата" },
            //Для фильтра по дате и/или месяцу
            { id: "contains", label: "По месяцу" },
            { id: "ends_with", label: "По месяцу и/или числу" },
          ],
          inputType: "date",
          id: "date-field",
        },
        datetime: {
          operators: [
            { id: "=", label: "=" },
            { id: "<>", label: "<>" },
            { id: "<", label: "<" },
            { id: "<=", label: "<=" },
            { id: ">", label: ">" },
            { id: ">=", label: ">=" },
          ],
          inputType: "datetime",
          id: "datetime-field",
        },
        numeric: {
          // operators: ['=', '<>', '<', '<=', '>', '>='],
          operators: [
            { id: "=", label: "=" },
            { id: "<>", label: "<>" },
            { id: "<", label: "<" },
            { id: "<=", label: "<=" },
            { id: ">", label: ">" },
            { id: ">=", label: ">=" },
          ],
          inputType: "number",
          id: "number-field",
        },
        custom: {
          operators: [],
          inputType: "text",
          id: "custom-field",
        },
        radio: {
          operators: [],
          choices: [],
          inputType: "radio",
          id: "radio-field",
        },
        checkbox: {
          operators: [],
          choices: [],
          inputType: "checkbox",
          id: "checkbox-field",
        },
        select: {
          operators: [],
          choices: [],
          inputType: "select",
          id: "select-field",
        },
        switch: {
          operators: ["="],
          id: "switch-field",
          inputType: "switch",
        },
        "multi-select": {
          operators: ["="],
          choices: [],
          inputType: "select",
          id: "multi-select-field",
        },
        subquery: {
          operators: ["has_query"],
          inputType: "query",
          id: "subquery-field",
        },
        phone: {
          operators: ["phone_equals"],
          inputType: "phone",
          id: "phone-field",
        },
      },
    };
  },

  computed: {
    mergedLabels() {
      return Object.assign({}, defaultLabels, this.labels);
    },

    mergedRules() {
      var mergedRules = [];
      var vm = this;

      vm.rules.forEach(function (rule) {
        if (typeof vm.ruleTypes[rule.type] !== "undefined") {
          mergedRules.push(Object.assign({}, vm.ruleTypes[rule.type], rule));
        } else {
          mergedRules.push(rule);
        }
      });

      return mergedRules;
    },

    vqbProps() {
      return {
        index: 0,
        depth: 1,
        maxDepth: this.maxDepth,
        ruleTypes: this.ruleTypes,
        rules: this.mergedRules,
        labels: this.mergedLabels,
        hideDefaultBtnClear: this.hideDefaultBtnClear,
      };
    },
  },

  mounted() {
    this.$watch(
      "query",
      (newQuery) => {
        console.log("VueQueryBuilder.watch.query", newQuery);
        if (JSON.stringify(newQuery) !== JSON.stringify(this.value)) {
          this.$emit("input", cloneDeep(newQuery));
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      "value",
      (newValue) => {
        console.log("VueQueryBuilder.watch.value", newValue);
        if (newValue === null) {
          this.query = {
            logicalOperator: this.labels.matchTypes[0].id,
            children: [],
          };
        } else if (JSON.stringify(newValue) !== JSON.stringify(this.query)) {
          this.query = cloneDeep(newValue);
        }
      },
      {
        deep: true,
      }
    );

    if (typeof this.$options.propsData.value !== "undefined") {
      this.query = Object.assign(this.query, this.$options.propsData.value);
    }
  },
};
</script>

<style lang="scss">
.vue-query-builder .vqb-group .rule-actions {
  margin-bottom: 20px;
}

.v-subheader {
  font-weight: bold;
}

.vue-query-builder .vqb-rule {
  /* margin-top: 15px;
    margin-bottom: 15px; */
  background-color: #f5f5f5;
  border-color: #ddd;
  // padding: 15px;
}

.vue-query-builder .vqb-group.depth-1 .vqb-rule,
.vue-query-builder .vqb-group.depth-2 {
  border-left: 2px solid #8bc34a;
}

.vue-query-builder .vqb-group.depth-2 .vqb-rule,
.vue-query-builder .vqb-group.depth-3 {
  border-left: 2px solid #00bcd4;
}

.vue-query-builder .vqb-group.depth-3 .vqb-rule,
.vue-query-builder .vqb-group.depth-4 {
  border-left: 2px solid #ff5722;
}

.vue-query-builder .close {
  opacity: 1;
  color: rgb(150, 150, 150);
}

@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
</style>
