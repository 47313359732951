<template>
  <v-card
      v-if="rule"
      class="vqb-rule card"
      outlined
  >
    <v-card-text>
      <v-row
          class="form-inline"
          align="baseline"
      >
        <v-col cols="auto">
          <label class="mr-5">
            <v-icon v-if="rule.is_main">
              mdi-star
            </v-icon>
            {{ rule.title || rule.label }}
          </label>
        </v-col>

        <!-- List of operands (optional) -->
        <v-col
            v-if="(typeof rule.operands !== 'undefined')"
            cols="auto"
        >
          <v-select
              dense
              v-model="query.operand"
              outlined
              hide-details
              :items="rule.operands"
              item-value="id"
              item-text="label"
              :rules="[(v) => !!v || 'Выберите']"
          />
        </v-col>

        <!-- List of operators (e.g. =, !=, >, <) -->
        <v-col
            v-if="
            typeof rule.operators !== 'undefined' && rule.operators.length > 1
          "
            cols="auto"
        >
          <v-select
              v-model="query.operator"
              outlined
              hide-details
              :items="rule.operators"
              item-value="id"
              item-text="label"
              style="max-width: 11em;"
              dense
          />
        </v-col>

        <v-col cols="auto">
          <!-- Basic text input -->
          <base-text-field
              dense
              v-if="rule.inputType === 'text' && !['empty', 'not_empty'].includes(query.operator)"
              v-model="query.value"
              :placeholder="rule.label"
              :rules="[(v) => !!v || `Введите ${rule.label}`]"
              clearable
          />

          <!-- switch -->
          <v-switch
              dense
              v-else-if="rule.inputType === 'switch'"
              v-model="query.value"
              inset
          />

          <!-- phone -->
          <vue-tel-input-vuetify
              dense
              v-else-if="rule.inputType === 'phone'"
              v-model="query.value"
          />

          <!-- Basic number input -->
          <base-text-field
              dense
              v-else-if="rule.inputType === 'number'"
              v-model="query.value"
              :placeholder="rule.label"
              v-bind="rule.props"
              type="number"
              :rules="[(v) => !!v || `Введите ${rule.label}`]"
              clearable
          />

          <!-- Datepicker -->
          <input
              dense
              v-else-if="rule.inputType === 'date-input'"
              v-model="query.value"
              class="form-control"
              type="date"
          >

          <!-- datetime-picker-field -->
          <template  v-else-if="rule.inputType === 'date'">
            <datetime-picker-field
                dense
                v-if="['=', '<>', '>', '>=', '<', '<='].includes(query.operator)"
                v-model="query.value"
                :with-time="false"
                placeholder="дд.мм.гггг"
                clearable
            />
            <base-text-field
                dense
                v-else-if="[
              'days_before_current_date', 'days_after_current_date', 'days_gte_current_date', 'days_lte_current_date',
              'diff_days_current_date', 'days_gt_current_date', 'days_lt_current_date', 'date_next_days', 'date_last_days'
            ].includes(query.operator)"
                v-model.number="query.value"
                type="number"
                placeholder="N"
                :rules="dayValueRule"
                suffix="дней"
                style="max-width: 9em;"
                maxlength="4"
                clearable
            />

            <!-- Поиск по месяцу -->
            <div v-else-if="query.operator === 'contains'">
              <v-text-field
                  dense
                  v-model="query.month"
                  type="text"
                  label="Месяц"
                  placeholder="От 1 до 12"
                  solo
                  :rules="[(v) => !!v || `Введите месяц`]"
                  @input="updateDateValueForMonth"
              ></v-text-field>
            </div>

            <!-- Поиск по числу /числу и месяцу -->
            <div v-else-if="query.operator === 'ends_with' || query.operator === 'contains'">
              <v-text-field
                  dense
                  v-model="query.day"
                  type="text"
                  label="День"
                  placeholder="От 1 до 31"
                  solo
                  :rules="[(v) => !!v || `Введите день`]"
                  @input="updateDateValue"
              ></v-text-field>

              <v-text-field
                  v-if="query.day"
                  dense
                  v-model="query.month"
                  type="text"
                  label="Месяц (опционально)"
                  placeholder="От 1 до 12"
                  solo
                  :rules="atLeastOneRule"
                  @input="updateDateValue"
                  :disabled="!query.day"
              ></v-text-field>
            </div>
          </template>

          <datetime-picker-field
              dense
              v-else-if="rule.inputType === 'datetime'"
              v-model="query.value"
              :with-time="true"
              placeholder="дд.мм.гггг чч:мм:cc"
              clearable
          />
          <!-- query -->
          <query-builder
              v-else-if="rule.inputType === 'query'"
              v-model="query.value"
              :rules="rule.rules"
          />
          <!-- Custom component input -->
          <div
              v-else-if="isCustomComponent && !['empty', 'not_empty'].includes(query.operator)"
              class="vqb-custom-component-wrap"
          >
            <component
                :is="rule.component"
                v-model="query.value"
                :rules="[(v) => !!v || `Введите ${rule.label}`]"
                v-bind="rule.props"
            />
          </div>

          <!-- Checkbox input -->
          <template v-else-if="rule.inputType === 'checkbox'">
            <div
                v-for="choice in rule.choices"
                :key="choice.value"
                class="form-check form-check-inline"
            >
              <input
                  :id="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
                  v-model="query.value"
                  type="checkbox"
                  :value="choice.value"
                  class="form-check-input"
              >
              <label
                  class="form-check-label"
                  :for="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
              >
                {{ choice.label }}
              </label>
            </div>
          </template>

          <!-- Radio input -->
          <v-radio-group
              v-else-if="rule.inputType === 'radio'"
              v-model="query.value"
              :rules="[(v) => !!v || `Введите ${rule.label}`]"
              row
          >
            <v-radio
                v-for="choice in rule.choices"
                :key="choice.value"
                :label="choice.label"
                :value="choice.value"
            />
          </v-radio-group>

          <!-- Select without groups -->

          <v-select
              dense
              v-else-if="rule.inputType === 'select' && !hasOptionGroups"
              v-model="query.value"
              outlined
              hide-details
              :items="selectOptions"
              :item-value="rule.itemValue || 'value'"
              :item-text="rule.itemText || 'label'"
              :multiple="rule.type === 'multi-select'"
              :rules="[(v) => !!v || `Введите ${rule.label}`]"
          />

          <!-- Select with groups -->
          <v-select
              dense
              v-else-if="rule.inputType === 'select' && hasOptionGroups"
              v-model="query.value"
              outlined
              hide-details
              :items="selectOptions"
              :item-value="rule.itemValue || 'value'"
              :item-text="rule.itemText || 'label'"
              :multiple="rule.type === 'multi-select'"
              :rules="[(v) => !!v || `Введите ${rule.label}`]"
          />
        </v-col>
        <!-- Remove rule button -->
        <v-spacer />

        <v-col cols="auto">
          <v-btn
              icon
              @click="remove"
          >
            <v-icon> $delete </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const cloneDeep  = require('clone-deep');
// import QueryBuilder from './../VueQueryBuilder'
// console.log('QueryBuilder', QueryBuilder)
// import QueryBuilder from '@/components/query-builder/VueQueryBuilder'
import DatetimePickerField from '@/components/base/DatetimePickerField.vue'
import { isFilled, isInteger, isPosNumber, isNegNumber } from "@/utils/validate";

export default {
  name: 'QueryBuilderRule',
  components: {
    QueryBuilder: () => import('./../VueQueryBuilder'),
    DatetimePickerField,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['query', 'index', 'rule', 'labels', 'depth'],

  data() {
    return {
      dayValueRule: [
        (v) => (isFilled(v) || v == 0) || "Введите значение",
        (v) => isInteger(v) || "Должно быть целым числом",
        (v) => !isNegNumber(v) || "Должно быть положительным",
        // (v) => v <= 86400 || "Не более 86400",
      ],
      atLeastOneRule: [v => !!this.query.day || 'Необходимо заполнить поле "День"'],
    }
  },

  computed: {
    isCustomComponent() {
      return this.rule.type === 'custom-component'
    },

    selectOptions() {
      return this.rule.choices || []

      // if (typeof this.rule.choices === 'undefined') {
      //   return {}
      // }

      // // Nest items to support <optgroup> if the rule's choices have
      // // defined groups. Otherwise just return a single-level array
      // return this.rule.choices.reduce(function (groups, item, index) {
      //   const key = item.group
      //   if (typeof key !== 'undefined') {
      //     groups[key] = groups[key] || []
      //     groups[key].push(item)
      //   } else {
      //     groups[index] = item
      //   }

      //   return groups
      // }, {})
    },

    hasOptionGroups() {
      return this.selectOptions.length && Array.isArray(this.selectOptions[0])
    },
  },

  beforeMount() {
    if (this.rule && this.rule.type === 'custom-component') {
      this.$options.components[this.id] = this.rule.component
    }
  },

  mounted() {
    const updatedQuery = cloneDeep(this.query)

    // Set a default value for these types if one isn't provided already
    if (this.query.value === null) {
      if (this.rule.inputType === 'checkbox') {
        updatedQuery.value = []
      }
      if (this.rule.type === 'select') {
        updatedQuery.value =
            this.rule.choices && this.rule.choices.length > 0
                ? this.rule.choices[0].value
                : null
      }
      if (this.rule.type === 'custom-component') {
        updatedQuery.value = null
        if (typeof this.rule.default !== 'undefined') {
          updatedQuery.value = cloneDeep(this.rule.default)
        }
      }

      this.$emit('update:query', updatedQuery)
    }
  },

  methods: {
    remove: function () {
      this.$emit('child-deletion-requested', this.index)
    },
    updateQuery(value) {
      const updatedQuery = cloneDeep(this.query)
      updatedQuery.value = value
      this.$emit('update:query', updatedQuery)
    },

    updateDateValue() {
      function padZero(value) {
        const numberValue = Number(value);
        return (numberValue >= 1 && numberValue < 10) ? '0' + numberValue : value;
      }

      if (this.query.month && this.query.day) {
        const paddedMonth = padZero(this.query.month);
        const paddedDay = padZero(this.query.day);
        this.query.value = `-${paddedMonth}-${paddedDay}`;
        this.query.operator = 'ends_with';
      } else if (this.query.month) {
        const paddedMonth = padZero(this.query.month);
        this.query.value = `-${paddedMonth}-`;
        this.query.operator = 'contains';
      } else if (this.query.day) {
        const paddedDay = padZero(this.query.day);
        this.query.value = `-${paddedDay}`;
        this.query.operator = 'ends_with';
      } else {
        this.query.value = null;
        this.query.operator = 'ends_with';
      }
    },

    updateDateValueForMonth()
    {
      function padZero(value) {
        const numberValue = Number(value);
        return (numberValue >= 1 && numberValue < 10) ? '0' + numberValue : value;
      }

      if (this.query.month) {
        const paddedMonth = padZero(this.query.month);
        this.query.value = `-${paddedMonth}-`;
        this.query.operator = 'contains';
      }
    },
  }
}
</script>
