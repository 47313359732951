var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"vqb-group card",class:'depth-' + _vm.depth.toString(),attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-text',{staticClass:"vqb-group-heading card-header"},[_c('v-row',{staticClass:"match-type-container form-inline",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"id":"vqb-match-type"},model:{value:(_vm.query.logicalOperator),callback:function ($$v) {_vm.$set(_vm.query, "logicalOperator", $$v)},expression:"query.logicalOperator"}},_vm._l((_vm.labels.matchTypes),function(label){return _c('v-btn',{key:label.id,attrs:{"value":label.id,"outlined":"","disabled":!_vm.hasMultipleRule,"color":"primary","small":""}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(label.label))])])}),1)],1),_c('v-spacer'),(_vm.existsPropAndFalse('hideDefaultBtnClear'))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',[(_vm.depth > 1)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $delete ")]),_vm._v(" "+_vm._s(_vm.labels.removeGroup)+" ")],1)],1):_vm._e(),(_vm.query.children.length>0)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":_vm.clearGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_feather-trash ")]),_vm._v(" Очистить ")],1)],1):_vm._e(),(_vm.exportAndImportEnabled)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical ")])],1)]}}],null,false,3031207279)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.importFile.click()}}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"importFile",attrs:{"type":"file","accept":"application/json"},on:{"change":_vm.uploadFile}}),_c('v-list-item-title',[_vm._v("Загрузить")])],1),(_vm.query.children.length > 0)?_c('v-list-item',{on:{"click":_vm.exportRules}},[_c('v-list-item-title',[_vm._v("Сохранить")])],1):_vm._e()],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"vqb-group-body card-body"},[_c('v-row',{staticClass:"rule-actions form-inline",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-autocomplete',{staticStyle:{"width":"300px"},attrs:{"loading":_vm.loading,"outlined":"","hide-details":"","items":_vm.rules,"item-value":"id","item-text":"label","placeholder":"Выберите условие","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var props = ref.props;
var item = ref.item;
return [(item.is_main)?_c('v-icon',[_vm._v(" mdi-star ")]):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var props = ref.props;
var item = ref.item;
return [(item.is_main)?_c('v-icon',[_vm._v(" mdi-star ")]):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")]}}]),model:{value:(_vm.selectedRule),callback:function ($$v) {_vm.selectedRule=$$v},expression:"selectedRule"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"secondary","disabled":!_vm.selectedRule,"small":""},on:{"click":_vm.addRule}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-outlined ")]),_vm._v(" "+_vm._s(_vm.labels.addRule)+" ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.depth < _vm.maxDepth)?_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":_vm.addGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-circle-outlined ")]),_vm._v(" "+_vm._s(_vm.labels.addGroup)+" ")],1):_vm._e()],1)],1),(!!_vm.query)?_vm._l((_vm.query.children),function(child,indexChild){return _c('v-row',{key:indexChild,attrs:{"dense":""}},[_c('v-col',[_c(child.type,{tag:"component",attrs:{"type":child.type,"query":child.query,"rule-types":_vm.ruleTypes,"rules":_vm.rules,"rule":_vm.ruleById(child.query.rule),"index":indexChild,"max-depth":_vm.maxDepth,"depth":_vm.depth + 1,"labels":_vm.labels},on:{"update:query":function($event){return _vm.$set(child, "query", $event)},"child-deletion-requested":_vm.removeChild}})],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }